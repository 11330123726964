var named_keys = {
	ArrowDown: 40,
	ArrowRight: 39,
	ArrowUp: 38,
	ArrowLeft: 37,
	Backspace: 8,
	Delete: 46,
	Tab: 9,
	Shift: 16,
	Escape: 27,
	a: 65,
	p: 80,
}

module.exports = named_keys;
